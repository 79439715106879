<template>
  <div class="l-wrapper loggedin">
    <AppHeader pageTitle="CSVフォーマットの新規作成" />
    <AppSideMenu />
    <main class="l-main">
      <ToastMessage :toastMsg.sync="toastMsg" />

      <div class="l-container">
        <ErrorMessage :errMsgs="errMsgs" />

        <CSVFormatEditor
          v-if="isAuthority"
          MODE="input"
          :errMsgs="errMsgs"
        />
      </div>
    </main>
    <AppFooter />
  </div>
</template>

<script>
// import { API_URL, DOMAIN } from "@/const";
import ToastMessage from "@/components/parts/ToastMessage";
import CSVFormatEditor from "../components/CSVformatEditor";

export default {
  name: "manifest-csvformat-input",
  components: {
    ToastMessage,
    CSVFormatEditor,
  },
  mixins: [],
  data() {
    return {
      isAdmin: this.$store.getters["userInfo/isAdmin"],
      isGenerator: this.$store.getters["userInfo/isGenerator"],
      isCollector: this.$store.getters["userInfo/isCollector"],
      isDisposer: this.$store.getters["userInfo/isDisposer"],
      errMsgs: [],
      toastMsg: "",
    };
  },
  methods: {},
  computed: {
    isAuthority() {
      return this.isGenerator || this.isCollector || this.isDisposer;
    },
  },
  watch: {},

  beforeCreate() {},
  created() {
    if (!this.isAuthority) {
      this.errMsgs.push("権限エラーです。");
    }
  },
  beforeMount() {},
  mounted() {},
};
</script>
